import React from 'react'

import AuthComplete from '@components/auth/AuthComplete'

function AuthCompletePage({ location: { state } }) {
  const { type } = state || {}

  return <AuthComplete type={type}></AuthComplete>
}

export default AuthCompletePage
