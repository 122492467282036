import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { FaChevronLeft } from 'react-icons/fa'

import AuthCompleteImage from '@assets/images/auth-complete.svg'

const Wrapper = styled.div`
  max-width: 300px;
  margin: ${({ theme }) => theme.spacers.large} auto;
  text-align: center;

  & > svg {
    width: 100%;
  }
`

const NavigateLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.main.primary};
  text-decoration: underline;
`

function getDesc(type) {
  switch (type) {
    case 'reset-password':
      return 'รหัสผ่านของคุณได้รับการเปลี่ยนเรียบร้อยแล้ว'
    case 'forgot-password':
      return 'ขั้นตอนการเปลี่ยนรหัสผ่านได้ถูกส่งไปยังอีเมล์ดังกล่าวเรียบร้อยแล้ว'
    case 'verify-email':
      return 'อีเมล์ของคุณได้รับการยืนยันแล้ว'
    case 'sign-up':
      return 'คุณได้สมัครสมาชิก Babel Coder เรียบร้อยแล้ว กรุณายืนยันอีเมล์ของคุณ'
    default:
      return ''
  }
}

function AuthComplete({ type }) {
  const [desc, setDesc] = useState('')
  useEffect(() => {
    setDesc(getDesc(type))
  }, [type, setDesc])

  return (
    <Wrapper>
      <AuthCompleteImage></AuthCompleteImage>
      <h2>การดำเนินการสำเร็จ</h2>
      <p>{desc}</p>
      <NavigateLink to="/" replace>
        <FaChevronLeft></FaChevronLeft>
        ย้อนกลับ
      </NavigateLink>
    </Wrapper>
  )
}

export default AuthComplete
